import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import Link from "next/link";

import mecoConference from "@/assets/images/icons/trusted-partners/img-featurein-Meco-Conference2.webp";
import nftNyc from "@/assets/images/icons/trusted-partners/img-featurein-NFT-nyc.webp";
import eurasiaPhoto from "@/assets/images/icons/trusted-partners/img-featurein-association.webp";
import awardPhoto from "@/assets/images/icons/trusted-partners/img-featurein-awards.webp";
import blockchainEconomy from "@/assets/images/icons/trusted-partners/img-featurein-blockchain-economy2.webp";
import blockchainExpo from "@/assets/images/icons/trusted-partners/img-featurein-blockchain-expo.webp";
import emergeConf from "@/assets/images/icons/trusted-partners/img-featurein-emerge.webp";
import teamZ from "@/assets/images/icons/trusted-partners/img-featurein-teamZ.webp";
import tmrwConf from "@/assets/images/icons/trusted-partners/img-featurein-tmrw-conf.webp";
import web3Wave from "@/assets/images/icons/trusted-partners/img-featurein-web3.webp";
import NFC from "@/assets/images/icons/trusted-partners/img-featurein-NFC.svg";
import parisBlockChain from "@/assets/images/icons/trusted-partners/img-paris-blockchain.webp";
import token2049 from "@/assets/images/icons/trusted-partners/Token-2049.webp";
import nftFest from "@/assets/images/icons/trusted-partners/NFTFEST.webp";
import Innoblock from "@/assets/images/icons/trusted-partners/innoblock.webp";
import metaTheory from "@/assets/images/icons/trusted-partners/meta_theory.webp";
import Image from "next/image";

function TrustedPartnersSection() {
  return (
    <>
      <div className="featurein center">
        <div className="featurein__header">
          <div className="hot__title h3 burst center home__featureIn_center">
            <BurstPuckerImageTitles />
            Trusted Partners<span style={{ marginLeft: "20px" }}></span>
            <BurstPuckerImageTitles />
          </div>
        </div>
        <div className="featurein__container center">
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://tmrwconf.net"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={124}
                  height={32}
                  loading="lazy"
                  src={tmrwConf}
                  alt="TMRW Conf"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Innovate the future with visionaries of tomorrow.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://blockchaineconomy.istanbul/en"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={blockchainEconomy}
                  alt="Blockchain Economy"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              The World&apos;s Most Global Blockchain & Crypto Conference&quot;
              awaits you.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.nft.nyc/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={96}
                  height={32}
                  loading="lazy"
                  src={nftNyc}
                  alt="NFT NYC"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              The leading nft event.&quot; Redefining digital asset ownership.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://emergeconf.io/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={50}
                  loading="lazy"
                  src={emergeConf}
                  alt="Emerge Conf"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">The top tech conference in the new east.</div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://web3wave.io/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={153}
                  height={30}
                  loading="lazy"
                  src={web3Wave}
                  alt="Web3 Wave"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Exploring the decentralized web&apos;s transformative potentials.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://en.eurasia-photo.com/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={112}
                  height={32}
                  loading="lazy"
                  src={eurasiaPhoto}
                  alt="Eurasia Photo"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Creating a bedrock for talented minds worldwide.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://blockchain-expo.com/global/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={108}
                  height={32}
                  loading="lazy"
                  src={blockchainExpo}
                  alt="Blockchain Expo"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Connecting the Blockchain & Web3 Ecosystem.&quot; Worldwide
              engagement
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://mecoconference.me/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={237}
                  height={32}
                  loading="lazy"
                  src={mecoConference}
                  alt="Meco Conference"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Mediterranean Embedded Computing Resources&quot;
            </div>
          </div>
          {/* Paris BlockChain Week */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.parisblockchainweek2024.com/speaker/7f14355d-21f0-ee11-aaf0-000d3a2b945f/alex-atashkar"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={70}
                  height={44}
                  loading="lazy"
                  src={parisBlockChain}
                  alt="Paris BlockChain Week"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Europe&apos;s biggest blockchain & digital assets event
            </div>
          </div>
          {/* teamZ */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://bsc.news/post/a-retrospective-of-teamz-web-3-ai-summit-2024"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={110}
                  height={40}
                  loading="lazy"
                  src={teamZ}
                  alt="teamZ"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Pioneers and experts in WEB3 and AI from Japan and abroad
            </div>
          </div>
          {/* wow summit */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.nonfungibleconference.com/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={80}
                  height={37}
                  loading="lazy"
                  src={NFC}
                  alt="NFC"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              NFC is an experimental Web3 event that brings the global NFT
              community together.
            </div>
          </div>
          {/* Awards Photo */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://awards.photos/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={100}
                  height={42}
                  loading="lazy"
                  src={awardPhoto}
                  alt="Awards Photos"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              A premier photography contest, recognises and rewards excellence.
            </div>
          </div>
          {/* token 2049 */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.asia.token2049.com/partners"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={100}
                  height={42}
                  loading="lazy"
                  src={token2049}
                  alt="token 2049"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              The World&apos;`s Largest Web3 and crypto Event
            </div>
          </div>
          {/* nft fest */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.nftfest.wtf/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={70}
                  height={42}
                  loading="lazy"
                  src={nftFest}
                  alt="NFTFEST"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              NFTFEST is a community-driven Web3 IRL event.
            </div>
          </div>
          {/* Inno Block */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.abga.asia/portal/innoblock/index/id/97.html"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={90}
                  height={42}
                  loading="lazy"
                  src={Innoblock}
                  alt="Innoblock"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Innoblock is a community-owned, Builder-central innovation
              festival
            </div>
          </div>
          {/* Metatheory studio */}
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://metatheory.studio/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={160}
                  height={44}
                  loading="lazy"
                  src={metaTheory}
                  alt="Metatheory studio"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              A premier photography contest, recognises and rewards excellence.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrustedPartnersSection;
